<template>
  <transition>
    <main>
      <section :class="{ big: size === 'big' }">
          <header>
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <span class="close" @click="$emit('close')"
              ><img src="/assets/images/cross.png"
            /></span>
          </header>
          <article>
            <template v-for="(item, index) in items" :key="index">
              <template v-if="item.type === 'text'">
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <input
                    :ref="`inpt-${item.label}`"
                    type="text"
                    :name="item.label"
                    :placeholder="`Insert ${item.label} here`"
                    @keyup="selectText(item.label)"
                    class="type-text"
                  />
                </div>
              </template>
              <template v-else-if="item.type === 'email'">
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <input
                    :ref="`inpt-${item.label}`"
                    type="email"
                    :name="item.label"
                    :placeholder="`Insert ${item.label} here`"
                    @keyup="selectText(item.label)"
                    class="type-text"
                  />
                </div>
              </template>
              <template v-else-if="item.type === 'select'">
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <form class="app-cover">
                    <div id="select-box">
                      <input
                        :ref="`cb-${item.label}`"
                        type="checkbox"
                        :id="`options-view-button-${item.label}`"
                        :class="`cb-${index}`"
                        @click="handleClicks"
                        @change="handleSel"
                      />
                      <div id="select-button" class="brd">
                        <div id="selected-value">
                          <span class="sel">
                            <template v-if="selects[item.label]">
                              <img
                                v-if="selects[item.label].img"
                                :src="selects[item.label].img"
                                class="sel-img"
                              />
                              <span class="sel-name">{{
                                selects[item.label].name
                              }}</span>
                            </template>
                            <template v-else
                              >Select {{ item.label }}</template
                            >
                          </span>
                        </div>
                        <div class="arrow">
                          <img
                            src="/assets/images/left-arrow.svg"
                            alt="close_chevron"
                          />
                        </div>
                      </div>
                      <div class="options">
                        <div
                          class="option"
                          v-for="(option, index) in filteredOptions[
                            item.label
                          ]"
                          :key="index"
                          @click="
                            selectOption(
                              item.label,
                              option.id,
                              option.name,
                              option.image,
                              null,
                              item.link ? item.link.to : null
                            )
                          "
                        >
                          <img v-if="option.image" :src="option.image" />
                          <span class="label">{{ option.name }}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </template>
              <template
                v-else-if="
                  item.type === 'select-linked' && selects[item.link.to]
                "
              >
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <form class="app-cover">
                    <div id="select-box">
                      <input
                        :ref="`cb-${item.label}`"
                        type="checkbox"
                        :id="`options-view-button-${item.label}`"
                        :class="`cb-${index}`"
                        @click="handleClicks"
                        @change="handleSel"
                      />
                      <div id="select-button" class="brd">
                        <div id="selected-value">
                          <span class="sel">
                            <template v-if="selects[item.label]">
                              <img
                                v-if="selects[item.label].img"
                                :src="selects[item.label].img"
                                class="sel-img"
                              />
                              <span class="sel-name">{{
                                selects[item.label].name
                              }}</span>
                            </template>
                            <template v-else
                              >Select {{ item.label }}</template
                            >
                          </span>
                        </div>
                        <div class="arrow">
                          <img
                            src="/assets/images/left-arrow.svg"
                            alt="close_chevron"
                          />
                        </div>
                      </div>
                      <div class="options">
                        <div
                          class="option"
                          v-for="(option, index) in filteredOptions[
                            item.link.to
                          ].filter(
                            (el) => el.id === selects[item.link.to].id
                          )[0][selects[item.link.to].link]"
                          :key="index"
                          @click="
                            selectOption(
                              item.label,
                              option.id,
                              option.name,
                              option.image
                            )
                          "
                        >
                          <img v-if="option.image" :src="option.image" />
                          <span class="label">{{ option.name }}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </template>
              <template v-else-if="item.type === 'multiple-select'">
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <div class="multiple-container">
                    <input
                      type="text"
                      :placeholder="`Insert ${item.label} here`"
                      v-model="search_key[item.label]"
                      @click="multipleShowAll[item.label] = true"
                      @keyup="searchMultiple(item.label)"
                      class="type-text"
                    />
                    <div
                      v-if="multipleShowAll[item.label]"
                      class="all-items"
                      v-click-outside="outside"
                    >
                      <div
                        class="multiple-search"
                        v-for="(element, index) in filteredOptions[
                          item.label
                        ]"
                        :key="index"
                        @click="addMultiple(item.label, element, index)"
                      >
                        <div class="item-info">
                          <span v-if="element.image" class="img"
                            ><img :src="element.image" alt=""
                          /></span>
                          <span v-else class="no-img">{{
                            element.name.charAt(0)
                          }}</span>
                          <span class="text">{{ element.name }}</span>
                        </div>
                        <span class="add-item">Add</span>
                      </div>
                      <div v-if="!filteredOptions[item.label].length">
                        No results found
                      </div>
                    </div>
                    <div
                      v-if="
                        selects[item.label] && !multipleShowAll[item.label]
                      "
                      class="selected-items"
                    >
                      <div
                        class="added-item"
                        v-for="(element, index) in selects[item.label]"
                        :key="index"
                        @click="removeMultiple(item.label, element, index)"
                      >
                        <div class="item-info">
                          <span v-if="element.image" class="img"
                            ><img :src="element.image" alt=""
                          /></span>
                          <span v-else class="no-img">{{
                            element.name.charAt(0)
                          }}</span>
                          <span class="text">{{ element.name }}</span>
                        </div>
                        <span class="remove-item">x</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'textarea'">
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <textarea
                    :ref="`inpt-${item.label}`"
                    :placeholder="`Insert ${item.label} here`"
                    @keyup="selectText(item.label)"
                  />
                </div>
              </template>
              <template v-else-if="item.type === 'choice'">
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <div class="gap">
                    <div class="choices">
                      <div
                        class="choice"
                        :class="{ active: isActive[item.label] === index }"
                        v-for="(choice, index) in item.options"
                        :key="index"
                        @click="handleChoice(item.label, index, choice.label)"
                      >
                        {{ choice.label }}
                      </div>
                    </div>
                    <form
                      class="app-cover"
                      v-if="
                        chosen.filter((el) => el.main === item.label).length
                      "
                    >
                      <div id="select-box">
                        <input
                          :ref="`cb-${item.label}`"
                          type="checkbox"
                          :id="`options-view-button-${item.label}`"
                          :class="`cb-${index}`"
                          @click="handleClicks"
                          @change="handleSel"
                        />
                        <div id="select-button" class="brd">
                          <div id="selected-value">
                            <span class="sel">
                              <template v-if="selects[item.label]">
                                <img
                                  v-if="selects[item.label].img"
                                  :src="selects[item.label].img"
                                  class="sel-img"
                                />
                                <span class="sel-name">{{
                                  selects[item.label].name
                                }}</span>
                              </template>
                              <template v-else
                                >Select
                                {{
                                  chosen.filter(
                                    (el) => el.main === item.label
                                  )[0].label
                                }}</template
                              >
                            </span>
                          </div>
                          <div class="arrow">
                            <img
                              src="/assets/images/left-arrow.svg"
                              alt="close_chevron"
                            />
                          </div>
                        </div>
                        <div class="options">
                          <div
                            class="option"
                            v-for="(option, index) in chosen.filter(
                              (el) => el.main === item.label
                            )[0].options"
                            :key="index"
                            @click="
                              selectOption(
                                item.label,
                                option.id,
                                option.name,
                                option.image,
                                chosen.filter(
                                  (el) => el.main === item.label
                                )[0].label
                              )
                            "
                          >
                            <img v-if="option.image" :src="option.image" />
                            <span class="label">{{ option.name }}</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'file'">
                <div
                  class="item"
                  @drop="drop"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <label :for="item.label">{{ item.label }}</label>
                  <div v-if="files.length" class="files">
                    <div
                      class="file"
                      v-for="(file, index) in files"
                      :key="index"
                    >
                      <div class="icon" @click="openFile(index)">
                        <img src="/assets/images/doc-icon.svg" alt="" />
                      </div>
                      <div class="info" @click="openFile(index)">
                        <div class="name">{{ file.name }}</div>
                        <div class="more">
                          <span>{{ file.pages }} pages</span>
                          <span>{{ file.size }}</span>
                          <span>{{ file.extension }}</span>
                        </div>
                      </div>
                      <div class="delete" @click="removeFile(index)">X</div>
                    </div>
                  </div>
                  <div class="import">
                    <label>
                      <input
                        ref="importFile"
                        type="file"
                        accept="application/pdf,application/vnd.ms-excel, text/plain"
                        multiple="multiple"
                        @change="importFiles"
                      />
                      Import File
                    </label>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'texteditor'">
                <div class="item texteditor">
                  <QuillEditor ref="quill" theme="snow" toolbar="full" />
                </div>
              </template>
              <template v-else-if="item.type === 'image'">
                <div class="item">
                  <label :for="item.label">{{ item.label }}</label>
                  <div v-if="images.length" class="images">
                    <div
                      class="file"
                      v-for="(img, index) in images"
                      :key="index"
                    >
                      <img :src="img.link" alt="" />
                      <div class="delete" @click="removeImage(index)">X</div>
                    </div>
                  </div>
                  <div class="import">
                    <label>
                      <input
                        ref="importImage"
                        type="file"
                        accept="image/png, image/jpeg"
                        @change="importImages"
                      />
                      Import Image
                    </label>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'boolean'">
                <div class="item bool">
                  <div class="boolean">
                    <label :for="item.label">{{ item.label }}</label>
                    <label class="switch">
                      <input
                        :ref="`boolean-${item.label}`"
                        type="checkbox"
                        @change="activateField($event, item.label)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div v-if="showExtra.includes(item.label)" class="extra">
                    <div
                      class="item"
                      v-for="(extra, extra_index) in item.extra"
                      :key="extra_index"
                    >
                      <template v-if="extra.type === 'text'">
                        <label :for="extra.label">{{ extra.label }}</label>
                        <input
                          :ref="`inpt-${extra.label}`"
                          type="text"
                          :name="extra.label"
                          :placeholder="`Insert ${extra.label} here`"
                          @keyup="selectText(extra.label)"
                          class="type-text"
                        />
                      </template>
                      <template v-if="extra.type === 'date'">
                        <label :for="extra.label">{{ extra.label }}</label>
                        <input
                          :ref="`inpt-${extra.label}`"
                          :placeholder="`Insert ${extra.label} here`"
                          type="date"
                          :name="extra.label"
                          @change="selectText(extra.label)"
                        />
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </article>
          <footer>
          <button type="submit" @click="submitData">
            <span>{{ title }}</span>
          </button>
        </footer>
      </section>
    </main>
  </transition>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  props: {
    items: {
      required: true,
      type: Array,
      default: () => [],
    },
    title: {
      required: true,
      type: String,
      default: () => "",
    },
    size: {
      required: false,
      type: String,
      default: () => "normal",
    },
  },

  data() {
    return {
      multipleShowAll: {},
      selects: {},
      imgURL: process.env.VUE_APP_DO_SPACES,
      search_key: {},
      filteredOptions: {},
      files: [],
      images: [],
      error: "",
      showExtra: [],
      content: "",
      chosen: [],
      isActive: {},
    };
  },

  components: {
    QuillEditor,
  },

  watch: {
    search_key: {
      deep: true,
      handler(val) {
        Object.keys(val).forEach((el) => this.searchMultiple(el));
      },
    },
  },

  methods: {
    handleSel(e) {
      const next = e.target.parentNode.parentNode.parentNode.nextElementSibling;
      if (next) {
        if (e.target.checked && next.classList.contains("submit"))
          next.style.marginTop = "120px";
        else next.style.marginTop = "";
      }
    },
    handleChoice(label, index, choice) {
      this.isActive[label] = index;
      this.selects[label] = { name: `Select ${choice}` };
      this.chosen.splice(
        this.chosen.map((element) => element.main).indexOf(label)
      );
      this.chosen.push({
        main: label,
        label: this.items.filter((el) => el.label === label)[0].options[index]
          .label,
        options: this.items.filter((el) => el.label === label)[0].options[index]
          .options,
      });
    },
    fillOptions() {
      this.items.forEach((element) => {
        const isPromise = (promise) => {
          return !!promise && typeof promise.then === "function";
        };

        if (element.type === "select") {
          if (isPromise(element.options)) {
            Promise.resolve(element.options).then((value) => {
              this.filteredOptions[element.label] = value;
            });
          } else this.filteredOptions[element.label] = element.options;
        }

        if (element.type === "choice") {
          this.isActive[element.label] = 0;
          if (isPromise(element.options[0].options)) {
            Promise.resolve(element.options[0].options).then((value) => {
              element.options[0].options = value;
              this.chosen.push({
                main: element.label,
                label: element.options[0].label,
                options: element.options[0].options,
              });
            });
          } else {
            this.chosen.push({
              main: element.label,
              label: element.options[0].label,
              options: element.options[0].options,
            });
          }

          element.options.forEach((el) => {
            if (isPromise(el.options)) {
              Promise.resolve(el.options).then((value) => (el.options = value));
            }
          });
        }

        if (element.type === "multiple-select") {
          if (isPromise(element.options)) {
            Promise.resolve(element.options).then((value) => {
              element.options = value;
              this.filteredOptions[element.label] = value;
            });
          } else {
            this.filteredOptions[element.label] = element.options;
          }
          this.multipleShowAll[element.label] = false;
        }
      });
    },

    searchMultiple(ref) {
      if (this.search_key[ref].length) {
        this.filteredOptions[ref] = this.items
          .filter((el) => el.label === ref)[0]
          .options.filter((el) =>
            el.name.toLowerCase().includes(this.search_key[ref].toLowerCase())
          );
      } else
        this.filteredOptions[ref] = this.items.filter(
          (el) => el.label === ref
        )[0].options;
    },

    selectText(name) {
      if (!Object.keys(this.selects).includes(name)) this.selects[name] = "";
      this.selects[name] = this.$refs["inpt-" + name].value;
    },

    selectOption(name, id, value, img, type, link) {
      if (this.$refs["cb-" + name]) this.$refs["cb-" + name].checked = false;
      if (!Object.keys(this.selects).includes(name)) this.selects[name] = {};

      if (!link) this.selects[name] = { id, name: value, img, type };
      else this.selects[name] = { id, name: value, img, type, link };

      if (this.selects[link]) this.selects[link] = {};
    },

    handleClicks(e) {
      const current = e.target.classList;
      const all = Array.from(
        document.querySelectorAll("#options-view-button")
      ).filter((el) => !el.classList.contains(current));
      all.forEach((el) => (el.checked = false));
    },

    outside() {
      Object.keys(this.multipleShowAll).forEach(
        (el) => (this.multipleShowAll[el] = false)
      );
    },

    addMultiple(name, item, index) {
      if (!Object.keys(this.selects).includes(name)) this.selects[name] = [];
      this.selects[name].push(item);
      this.filteredOptions[name].splice(index, 1);
      if (!this.filteredOptions[name].length) {
        this.outside();
        this.search_key[name] = "";
      }
    },

    removeMultiple(name, item, index) {
      this.selects[name].splice(index, 1);
      this.filteredOptions[name].push(item);
    },

    bytesToSize(bytes) {
      const sizes = ["bytes", "kb", "mb", "gb", "tb"];
      if (bytes == 0) return "0 Byte";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    importImages(event) {
      const file = event.target.files[0];

      if (FileReader && file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          this.images.push({
            name: file.name,
            link: fr.result,
            originalFile: file,
          });

          this.$refs.importImage.value = "";
        };
      }
    },

    importFiles(e) {
      e.target.files.forEach((file) => {
        let filePages = 0;
        const today = new Date();
        const date =
          today.getDate() +
          "/" +
          (today.getMonth() + 1) +
          "/" +
          today.getFullYear();

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = () => {
          filePages = reader.result.match(/\/Type[\s]*\/Page[^s]/g)
            ? reader.result.match(/\/Type[\s]*\/Page[^s]/g).length
            : "?";
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            if (file.size < 10485760) {
              this.files.push({
                name: file.name,
                pages: filePages,
                size: this.bytesToSize(file.size),
                extension: file.name.split(".")[1].toUpperCase(),
                imported_at: date,
                link: reader.result,
                og_doc: file,
              });
            } else this.error = "File is too big";

            this.$refs.importFile.value = "";
          };
        };
      });
    },

    drop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;

      const toImport = {
        target: {
          files,
        },
      };
      this.importFiles(toImport);
    },

    openFile(e) {
      let fileWindow = window.open("");
      fileWindow.document.write(`
                <html>
                <head>
                <title>
                    ${this.files[e].name}
                </title>
                </head>
                <body style="margin: 0 auto;">
                    <embed width='100%' height='100%' src="${this.files[e].link}" />
                </body>
                </html>`);
    },

    removeFile(e) {
      this.files.splice(e, 1);
    },

    removeImage(e) {
      this.images.splice(e, 1);
    },

    activateField(event, item) {
      const value = event.target.checked;
      this.selects[item] = value;
      if (value) this.showExtra.push(item);
      else this.showExtra.splice(this.showExtra.indexOf(item), 1);
    },

    submitData() {
      if (this.items.filter((el) => el.type === "texteditor").length) {
        this.selects.texteditor = this.$refs.quill.getHTML();
      }
      this.selects.Files = this.files;
      this.selects.Images = this.images;
      Object.keys(this.$refs).forEach((element) => {
        if (
          this.$refs[element].type === "text" ||
          this.$refs[element].type === "textarea"
        ) {
          this.selects[element.replace("inpt-", "")] =
            this.$refs[element].value;
        }
      });
      this.$emit("submitData", this.selects);
      this.$emit("close");
      this.selects = [];
      this.files = [];
      this.images = [];

      Object.keys(this.$refs).forEach((element) => {
        this.$refs[element].value = "";
      });
    },
  },

  mounted() {
    this.fillOptions();
  },
};
</script>
<style lang="scss" scoped>
main {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(100,100,100,0.4);
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;

	section {
		background: white;
		width: 95%;
		max-width: 550px;
		height: 95%;
		max-height: 800px;
		border-radius: 2px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: relative;

		header, article, footer {
      padding: 20px;
    }

		header {
			height: 10%;
			box-shadow: 0px 1px 6px #e0e0e0;
			border-bottom: 1px solid #eeeeee;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: sticky;
			top: 0;

			h5, div {
				font-weight: 600;
				font-size: 1.3rem;
			}

			.out {
				user-select: none;
				cursor: pointer;
				transition: 0.2s;

				&:hover {
					color: grey;
				}
			}
		}

    article {
      height: 80%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow: auto;
    }

    footer {
        height: 10%;
        box-shadow: 0px 1px 6px #e0e0e0;
        border-top: 1px solid #eeeeee;
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;

        &.err {
            justify-content: space-between;
        }

        .errors {
            width: 70%;
            color: $red;
            text-transform: capitalize;
            overflow-wrap: break-word;
        }

        button {
            color: white;
            padding: 10px 25px;
            text-transform: capitalize;
            background: var(--primary-color);
            user-select: none;
            cursor: pointer;
            transition: 0.2s;
            min-width: 10rem;
            border: none;

            &:focus {
                outline: none;
            }

            &:hover {
                background: var(--primary-hover);
            }

            .loading {
                height: unset;
                background: transparent;

                .loader {
                    width: 20px;
                    height: 20px;
                    border: 2px solid #f3f3f3;
                    border-top: 2px solid var(--primary-color);
                }
            }
        }
    }
  }
}

.modal {
  width: 400px;
  overflow: hidden;
  &.big {
    width: 800px;
  }
}

.modal-body .content {
  height: 65vh;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
}

.modal-body .content::-webkit-scrollbar {
  width: 10px;
}
.modal-body .content::-webkit-scrollbar-track {
  background: transparent;
}
.modal-body .content::-webkit-scrollbar-thumb {
  background: #f2f5f8;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  transition: 0.2s;
}
.modal-body .content::-webkit-scrollbar-thumb:hover {
  background: #e6e8eb;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.close {
  cursor: pointer;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.texteditor {
    min-height: 400px;
    margin: 30px 0;
  }
  .gap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .choices {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: solid 1px #cad6e2;
      border-radius: 5px;
      .choice {
        width: -webkit-fill-available;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        text-transform: capitalize;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
        font-weight: 500;
        border-right: solid 1px #cad6e2;

        &.active {
          background: #cad6e2;
          color: white;
        }

        &:hover {
          background: #b9c2ca;
          color: white;
        }

        &:last-of-type {
          border-right: none;
        }
      }
    }
  }
  .images {
    display: flex;
    justify-content: center;
    padding: 10px;
    .file {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      img {
        height: 100%;
        object-fit: cover;
      }
      .delete {
        position: absolute;
        color: red;
        z-index: 4;
        top: 3px;
        right: 3px;
        cursor: pointer;
        font-size: 0.7rem;
        font-weight: bold;
      }
    }
  }
  .files {
    display: flex;
    flex-direction: column;
    padding: 0 0 10px 0;
    .file {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 0;
      border-bottom: solid 1px #cad6e2;
      position: relative;

      &:last-of-type {
        border: none;
      }
      .info {
        .name {
          font-weight: bold;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 300px;
          white-space: nowrap;
        }
        .more {
          display: flex;
          gap: 5px;
          font-size: 0.7rem;
        }
      }

      .delete {
        position: absolute;
        color: red;
        z-index: 4;
        right: 10px;
      }
    }
  }

  .boolean {
    display: flex;
    gap: 10px;
  }

  .extra {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    input {
      border: solid 1px #cad6e2;
      border-radius: 5px;
      padding: 5px 10px;
    }
  }
}

.item label {
  font-weight: 500;
}

.item textarea {
  border: solid 1px #cad6e2;
  border-radius: 5px;
  resize: none;
  padding: 5px 10px;
  height: 100px;
}
.item textarea:focus {
  outline: none;
}
.item .import label {
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: solid 1px #0072FF56;
  border-style: dashed;
  border-radius: 5px;
  transition: 0.2s;
}
.item .import label:hover {
  background: #0072FF;
  color: white;
  border: solid 1px transparent;
}

.item .import label input {
  display: none;
}

.item .type-text {
  border-radius: 5px;
  border: solid 1px #cad6e2;
  padding: 5px 10px;
  height: 40px;
}

.item .multiple-container {
  border: solid 1px #cad6e2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 150px;
  overflow: auto;
}

.item .multiple-container::-webkit-scrollbar {
  width: 10px;
}
.item .multiple-container::-webkit-scrollbar-track {
  background: transparent;
}
.item .multiple-container::-webkit-scrollbar-thumb {
  background: #f2f5f8;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  transition: 0.2s;
}
.item .multiple-container::-webkit-scrollbar-thumb:hover {
  background: #e6e8eb;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.item .multiple-container input {
  border: solid 1px #cad6e2;
  height: 30px;
}

.item .multiple-container .all-items {
  height: 150px;
  overflow: auto;
  border: solid 1px #cad6e2;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
}

.item .multiple-container .all-items::-webkit-scrollbar {
  width: 10px;
}
.item .multiple-container .all-items::-webkit-scrollbar-track {
  background: transparent;
}
.item .multiple-container .all-items::-webkit-scrollbar-thumb {
  background: #f2f5f8;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  transition: 0.2s;
}
.item .multiple-container .all-items::-webkit-scrollbar-thumb:hover {
  background: #e6e8eb;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.item .multiple-container .all-items .multiple-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: #cad6e2;
  transition: 0.2s;
  cursor: pointer;
  user-select: none;
}
.item .multiple-container .all-items .multiple-search:hover {
  background: #cad6e2;
}

.item .multiple-container .all-items .multiple-search .item-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.item .multiple-container .all-items .multiple-search .item-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.item .multiple-container .all-items .multiple-search .item-info .img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}
.item .multiple-container .all-items .multiple-search .item-info .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-info .no-img {
  width: 25px;
  height: 25px;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.item-info .text {
  width: calc(100% - 40px);
  padding: 0 10px 0 0;
  font-size: 0.7rem;
}
.item .multiple-container .all-items .multiple-search .add-item {
  background: var(--primary-color);
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
}
.item .multiple-container .all-items .multiple-search .add-item:hover {
  background: #1d4ec0;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
}

.item .multiple-container .selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.item .multiple-container .selected-items .added-item {
  width: calc(50% - 5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}
.item .multiple-container .selected-items .added-item:hover {
  background: #cad6e2;
}
.item .multiple-container .selected-items .added-item .item-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.item .multiple-container .selected-items .added-item .item-info .img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}
.item .multiple-container .selected-items .added-item .item-info .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item .multiple-container .selected-items .added-item .remove-item {
  color: rgb(163, 10, 10);
  transition: 0.2s;
}
.item .multiple-container .selected-items .added-item .remove-item:hover {
  color: rgb(97, 10, 10);
}

.options {
  display: none;
  position: absolute;
  left: 0;
  height: 120px;
  max-height: 150px;
  overflow: auto;
  border-radius: 5px;
  width: 100%;
  background: white;
}

.options::-webkit-scrollbar {
  width: 10px;
}

.options::-webkit-scrollbar-track {
  background: transparent;
}

.options::-webkit-scrollbar-thumb {
  background: #f2f5f8;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  transition: 0.2s;
}

.options::-webkit-scrollbar-thumb:hover {
  background: #e6e8eb;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.option {
  position: relative;
  line-height: 1;
  transition: 0.3s ease all;
  z-index: 2;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  display: none;
  min-width: 150px;
}

.option img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.option,
.label {
  font-size: 16px;
}

input[id*="options-view-button"]:checked ~ .options {
  display: block;
}

input[id*="options-view-button"]:checked ~ .options .option {
  display: flex;
  background: white;
}

input[id*="options-view-button"]:checked ~ .options .option:hover {
  background: #f2f5f8;
  border-radius: 5px;
}

#select-button {
  border-radius: 5px;
  border: solid 1px #cad6e2;
  cursor: pointer;
  position: relative;
  padding: 5px 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#select-button .arrow {
  width: 10px;
}

#select-button .arrow img {
  opacity: 0.5;
  width: 100%;
  transform: rotate(270deg);
  transition: 0.2s;
}

input[id*="options-view-button"]:checked ~ #select-button .arrow img {
  transform: rotate(90deg);
}

#select-box {
  position: relative;
}

input[id*="options-view-button"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

#selected-value {
  font-size: 16px;
  line-height: 1;
  margin-right: 26px;
  text-transform: capitalize;
}

input[id*="options-view-button"]:checked ~ .options {
  border: 1px solid #e2eded;
  border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  z-index: 4;
}

.label {
  display: none;
  padding: 0;
}

input[id*="options-view-button"]:checked ~ .options .label {
  display: block;
}

.s-c {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
}

.s-c.top {
  top: 0;
}

.s-c.bottom {
  bottom: 0;
}

input[type="radio"] {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 50%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.s-c:hover ~ i {
  color: #fff;
  opacity: 0;
}

.s-c:hover {
  height: 100%;
  z-index: 1;
}

.label {
  transition: 0.3s ease all;
}

.opt-val {
  position: absolute;
  left: 14px;
  width: 217px;
  height: 21px;
  opacity: 0;
  background-color: #fff;
  transform: scale(0);
}

.option input[type="radio"]:checked ~ .opt-val {
  opacity: 1;
  transform: scale(1);
}

.option input[type="radio"]:checked ~ img {
  top: 0;
  bottom: auto;
  opacity: 1;
  animation: unset;
}

.option input[type="radio"]:checked ~ img,
.option input[type="radio"]:checked ~ .label {
  color: #fff;
}

.option input[type="radio"]:checked ~ .label:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

input[id*="options-view-button"]:not(:checked)
  ~ .options
  .option
  input[type="radio"]:checked
  ~ .opt-val {
  top: -30px;
}
input[id*="options-view-button"]:not(:checked) ~ .options {
  z-index: 1;
}

.option input[type="radio"]:checked ~ .label:before {
  background-color: #000;
  border-radius: 4px;
}

#option-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  transition: 0.3s ease all;
  z-index: 1;
  display: none;
}

input[id*="options-view-button"]:checked ~ .options #option-bg {
  display: block;
}

.optionhover ~ #option-bg {
  top: 0;
  background-color: rgba(97, 97, 97, 0.692);
  border-radius: 4px 4px 0 0;
}

#ytd-url {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px 14px;
  margin: 20px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  background-color: #143240;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(20, 50, 64, 0.76);
}

.sel {
  display: flex;
  align-items: center;
  gap: 5px;
}
.sel .sel-img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
}
.submit {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.submit span {
  padding: 10px 25px;
  border-radius: 30px;
  background: var(--primary-color);
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}
.submit span:hover {
  background: #1d4ec0;
}
</style>